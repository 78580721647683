<template>
  <div>
    <div class="offlineExam">
      <b-container fluid v-if="!enableManageQuestionWindow">
        <div class="loading" v-if="fetchData">
          <load-data></load-data>
        </div>
        <b-row v-else>
          <b-col md="12">
            <b-modal id="deleteQuestion" no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-footer centered
              hide-header size="md">
              <p class="text-danger" style="font-size: 16px; font-weight: 600; text-align: center">
                Deleting this question will also delete the choices, answer,
                attachment, score, and the question-co mapping. Please click on
                continue to proceed. ?
              </p>
              <div class="buttons d-flex justify-content-between">
                <b-button variant="primary" size="sm" @click="deleteQuestion()">Continue</b-button>
                <b-button variant="danger" size="sm" @click="$bvModal.hide('deleteQuestion')">Cancel</b-button>
              </div>
            </b-modal>
            <!-- <b-form-checkbox v-model="importFromExcel">Import From Excel Sheet</b-form-checkbox> -->
            <!-- <ImportFromExcelTypeTwo :exam="exam" :courseModulesOptions="courseModulesOptions"
              :courseoutcomesOptions="courseoutcomesOptions" :bloomTaxonomyOptions="bloomTaxonomyOptions"
              :students="students" v-if="importFromExcel" @importedFromExcel="importedFromExcel" /> -->
            <div>
              <b-form @submit.prevent="saveQuestionsAndScores()" class="border-0">
                <!-- <div class="w-100 d-flex justify-content-between mb-3"> -->
                <b-row class="mb-2">
                  <b-col v-if="exam.offline_activity">
                    <b-button variant="primary" size="md" pill @click="openManageQuestionWindow()">Add/Edit
                      Question</b-button>
                  </b-col>
                  <b-col v-if="exam.is_objective_only">
                    Generate objective answer score <span class="text-danger" v-if="autoEvaluationStatus">Please wait...
                    </span>
                    <b-button v-else variant="primary" size="md" pill @click="genarateObjectiveScore()">Proceed
                    </b-button>
                  </b-col>
                  <b-modal id="importExcelModel" size="lg" v-model="viewImportExcelModel" title="Import excel for score"
                    hide-footer>
                    <div>
                      <b-form-file v-if="!importExcelFile" v-model="importExcelFile" accept=".xlsx" size="sm"
                        @change="importScoreExcel" placeholder="Choose an excel file or drop it here..."
                        drop-placeholder="Drop file here...">
                      </b-form-file>
                      <span v-if="importExcelFile" class="text-text-danger">{{ excelImportStatus }}</span>
                    </div>

                    <div>
                      <p style="font-size: 14px; font-weight: 600">Scores Excel Sheet</p>
                      <h6 style="font-size: 14px; font-weight: 900">
                        Note: Please do not include headings in the spreadsheet used to import data.
                        Enter only the values.
                      </h6>

                      <p>Sample excel format to import marks</p>
                      <img src="../../../../../assets/images/scores.png" alt="" class="img-fluid mx-auto d-block" />
                      <br>
                      <p>The imported data will look like this</p>
                      <img style="height: 250px;width: 400px;"
                        src="../../../../../assets/images/activityscoreimportoutput.png" alt=""
                        class="img-fluid mx-auto d-block" />
                    </div>
                  </b-modal>
                  <b-col class="text-left">
                    <b-button class="mb-2" variant="primary" v-b-modal.importExcelModel>Import excel</b-button>
                    <!-- <b-form-file v-if="!importExcelFile" v-model="importExcelFile" accept=".xlsx" size="sm"
                      @change="importScoreExcel" placeholder="Choose an excel file or drop it here..."
                      drop-placeholder="Drop file here...">
                    </b-form-file>
                    <span v-if="importExcelFile" class="text-text-danger">{{ excelImportStatus }}</span> -->
                  </b-col>
                  <b-col class="text-left">
                    <b-button class="mb-2" @click="prepareExport()" variant="primary">Export to Excel</b-button>
                  </b-col>
                  <b-col class="text-right">
                    <b-button :disabled="saveBtn.disabled || questions.length == 0" variant="success"
                      @click="saveAndPublishResult()" size="md" pill>
                      {{ saveBtn.disabled ? saveBtn.text : 'Save & Send SMS' }}
                    </b-button>
                  </b-col>
                  <b-col class="text-right">
                    <b-button :disabled="saveBtn.disabled || questions.length == 0" variant="success" type="Submit"
                      size="md" pill>{{ saveBtn.text }}</b-button>
                  </b-col>

                  <!-- <b-col>

                    <div class="closeBtn mb-2 text-right">
                      <span v-if="exam_id == null" @click="closeCurrentTab()" class="btn m-0">
                        <i class="fas fa-window-close fa-4x text-danger"></i>
                      </span>
                      <span v-else @click="$root.clearSubContent" class="btn m-0">
                        <i class="fas fa-window-close fa-4x text-danger"></i>
                      </span>
                    </div>
                  </b-col> -->

                </b-row>
                <!-- </div> -->
                <b-overlay :show="loadExams" spinner-variant="primary" spinner-type="grow" spinner-small rounded="md">
                  <div class=" " style="height: 80vh !important;">
                    <table class="table table-bordered table-striped" id="institutionUserDetails">
                      <thead class="thead-dark" style="font-size: 14px; font-weight: 600">
                        <tr style="z-index: 100; position: sticky; top: 0px;">
                          <th>
                            <span v-for="(vHeader, vHeaderIndex) in viewHeader" :key="vHeaderIndex">
                              <i v-if="!vHeader" @click="viewHeader[vHeaderIndex] = true" class="fas fa-eye"></i>
                            </span>
                          </th>
                          <th>Question</th>
                          <th v-for="(question, questionIndex) in evaluationQuestions" :key="questionIndex">
                            <div class="">
                              <span v-if="edit">{{
                                  question.question_no
                              }}</span>
                              <b-form-input :required="true" v-model="question.question_no" placeholder="Ques No."
                                v-else></b-form-input>
                              <br>

                              <!-- <span style="align-self: center" v-if="question.id != null"
                                class="text-danger btn p-0 m-0 ml-2" @click="checkDeleteQuestion(questionIndex)"><i
                                  class="fas fa-trash-alt"></i>
                                </span>

                              <span v-else style="align-self: center" class="text-danger btn p-0 m-0 ml-2"
                                @click="questions.splice(questionIndex, 1)"><i class="fas fa-minus-circle"></i></span> -->
                            </div>
                          </th>
                          <th></th>
                          <th></th>
                        </tr>
                        <tr v-if="viewHeader.maxMark">
                          <th>
                            <i @click="viewHeader.maxMark = !viewHeader.maxMark" :class="
                              viewHeader.maxMark
                                ? 'far fa-eye-slash'
                                : 'fas fa-eye'
                            "></i>
                          </th>
                          <th>Max Marks</th>
                          <th v-for="(question, questionIndex) in evaluationQuestions" :key="questionIndex">
                            <span v-if="edit || (exam.positive_mark != null)">{{ exam.positive_mark != null ?
                                `${exam.positive_mark} or ${exam.negative_mark}` : question.maximum_mark
                            }}</span>
                            <span v-else>
                              <!-- <span v-if="
                              question.orquestion_group_id != null &&
                              question.orquestion_group_id in
                              orquestiongroups">
                                {{ orquestiongroups[question.orquestion_group_id].maximum_mark_of_question }}
                              </span> -->
                              <b-form-input :required="true" @change="updateTotalQuestionScore"
                                v-model="question.maximum_mark" placeholder="Marks">
                              </b-form-input>
                            </span>
                          </th>
                          <th></th>
                          <th></th>
                        </tr>
                        <tr v-if="viewHeader.co">
                          <th>
                            <i @click="viewHeader.co = !viewHeader.co" :class="
                              viewHeader.co
                                ? 'far fa-eye-slash'
                                : 'fas fa-eye'
                            "></i>
                          </th>
                          <th>CO Mapping</th>

                          <th v-for="(question, questionIndex) in evaluationQuestions" :key="questionIndex">
                            <span v-if="edit">
                              <span v-if="question.co_mapping.length == 0">-</span>
                              <span v-else v-for="(co, coIndex) in question.co_mapping" :key="coIndex">
                                {{ co.name }}
                                <span v-if="
                                  coIndex != question.co_mapping.length - 1
                                ">,</span>
                              </span>
                            </span>
                            <div v-else>
                              <multiselect v-model="question.co_mapping" :options="courseoutcomesOptions"
                                :multiple="true" :close-on-select="false" :clear-on-select="false"
                                placeholder="Select CO's" label="name" track-by="value" :preselect-first="false"
                                openDirection="bottom">
                                <template slot="selection"><span class="multiselect__single d-flex flex-wrap"
                                    v-if="question.co_mapping.length">
                                    <span v-for="(co,
                                    coIndex) in question.co_mapping" :key="coIndex">
                                      {{ co.name }} ,
                                    </span>
                                  </span></template>
                              </multiselect>
                            </div>
                          </th>
                          <th></th>
                          <th></th>
                        </tr>
                        <tr v-if="viewHeader.module">
                          <th>
                            <i @click="viewHeader.module = !viewHeader.module" :class="
                              viewHeader.module
                                ? 'far fa-eye-slash'
                                : 'fas fa-eye'
                            "></i>
                          </th>
                          <th>Module Mapping</th>
                          <th v-for="(question, questionIndex) in evaluationQuestions" :key="questionIndex">
                            <span v-if="edit">
                              <span v-if="question.module_id == null">-</span>
                              <span v-else>
                                {{
                                    courseModulesOptionsObject[question.module_id]
                                }}
                              </span>
                            </span>
                            <b-form-select v-else v-model="question.module_id" :options="courseModulesOptions">
                            </b-form-select>
                          </th>
                          <th></th>
                          <th></th>
                        </tr>
                        <tr v-if="viewHeader.blooms">
                          <th>
                            <i @click="viewHeader.blooms = !viewHeader.blooms" :class="
                              viewHeader.blooms
                                ? 'far fa-eye-slash'
                                : 'fas fa-eye'
                            "></i>
                          </th>
                          <th>Blooms Taxonomy</th>
                          <th v-for="(question, questionIndex) in evaluationQuestions" :key="questionIndex">
                            <span v-if="edit">
                              <span v-if="question.taxonomy == null">-</span>
                              <span v-else>
                                {{
                                    bloomTaxonomyOptionsObject[question.taxonomy]
                                }}
                              </span>
                            </span>
                            <b-form-select v-else v-model="question.taxonomy" :options="bloomTaxonomyOptions">
                            </b-form-select>
                          </th>
                          <th></th>
                          <th></th>
                        </tr>

                        <!-- <tr v-if="viewHeader.orq">
                          <th>
                            <i @click="viewHeader.orq = !viewHeader.orq" :class="
                              viewHeader.orq
                                ? 'far fa-eye-slash'
                                : 'fas fa-eye'
                            "></i>
                          </th>
                          <th>
                            OR question grouping
                            <b-button variant="info" class="text-white sm" size="sm"
                              @click="examorQuestiongroupmodel(true)">Add/edit</b-button>
                          </th>
                          <th v-for="(question, questionIndex) in evaluationQuestions" :key="questionIndex">
                            <select class="custom-select" v-model="question.orquestion_group_id">
                              <option :value="null">Select group</option>
                              <option v-for="(group, groupindex) in orquestiongroups" :key="groupindex"
                                :value="group.id" :selected="
                                  group.id == question.orquestion_group_id
                                ">{{ group.summary }}</option>
                            </select>
                          </th>
                          <th></th>
                          <th></th>
                        </tr> -->

                        <tr>
                          <th>Roll No.</th>
                          <th>Name</th>
                          <th v-for="(question, questionIndex) in evaluationQuestions" :key="questionIndex">
                            Score
                          </th>
                          <th>
                            Total Score
                          </th>
                          <th>Percentage</th>
                        </tr>
                      </thead>
                      <tbody style="border: 0px;" v-for="(student, studentIndex) in students" :key="studentIndex">
                        <tr style="font-size: 14px; font-weight: 600">
                          <td class="p-0">{{ student.roll_number }}</td>
                          <td class="p-0 pl-1 text-left">
                            {{ student.first_name || " " }}
                            {{ student.last_name || " " }}
                            <br>
                            <div v-if="!exam.offline_activity">
                              <span v-if="expandedEvaluationStudId != student.id" style="align-self: center"
                                class="text-info btn p-0 m-0 ml-2" @click="viewStudEvaluation(student.id)"><i
                                  class="fas fa-search-plus"></i></span>
                              <span v-else style="align-self: center" class="text-success btn p-0 m-0 ml-2"
                                @click="expandedEvaluationStudId = null"><i class="fas fa-search-minus"></i></span>
                            </div>
                          </td>
                          <td v-for="(question, questionIndex) in evaluationQuestions" :key="questionIndex">
                            <span v-if="edit">{{
                                question.scores[student.id]
                            }}</span>
                            <b-form-input style="max-width: 130px;" :disabled="expandedEvaluationStudId == student.id"
                              v-model="question.scores[student.id]" placeholder="Score"
                              :class="{ 'is-invalid': validateScoreInput(question, student.id), 'text-center': true }"
                              v-else>
                            </b-form-input>
                          </td>
                          <td class="align-middle text-center">
                            <span v-if="edit">{{ updateTotalMarks[student.id] }} / {{ totalMarks }}</span>
                            <span v-else>{{ updateTotalMarks[student.id] }}/{{ totalMarks }}</span>
                          </td>
                          <td class="align-middle text-center">
                            <span v-if="updateTotalMarks[student.id]">
                              <span v-if="edit">{{
                                  Math.floor(
                                    (updateTotalMarks[student.id] / totalMarks) *
                                    100
                                  )
                              }}</span>
                              <span v-else>{{
                                  Math.floor(
                                    (updateTotalMarks[student.id] / totalMarks) *
                                    100
                                  ) + "%"
                              }}</span>
                            </span>
                          </td>
                        </tr>
                        <tr v-if="expandedEvaluationStudId == student.id">
                          <td :colspan="questions.length + 4" class="p-1">
                            <StudentExamEvaluation @updateStudScore="updateStudentQuestionScoreFromEvaluation"
                              :student_id="student.id" :exam_id="exam.id" :exam="exam"
                              :activeStudentQuestionsScores="activeStudentQuestionsScores" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-overlay>
              </b-form>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <div v-else>
        <b-row> <b-col class="text-right mb-1"> <b-button variant="danger" @click="closeManageQuestionWindow">Return to
              Score Window</b-button> </b-col> </b-row>
        <ManageExamQuestions lazy :exam="exam" />
      </div>

      <!-- //orquestion group manage -->

      <!-- <b-modal v-if="enableOrQuestiongroupmodel" :id="'orQuestiongroupmodel'" no-close-on-backdrop no-close-on-keyboard
        no-close-on-esc hide-footer hide-header size="xl">
        <b-container>
          <b-row>
            <b-col style="text-align:right;">
              <b-button variant="danger" size="sm" @click="examorQuestiongroupmodel(false)">close</b-button>
            </b-col>
          </b-row>
          <br />
          <ExamorquestiongroupDash :exam_id="examId" />
        </b-container>
      </b-modal> -->
    </div>
  </div>
</template>

<script>
import { required, requiredIf, integer } from "vuelidate/lib/validators";
// import ImportFromExcelTypeTwo from "./ImportFromExcelTypeTwo.vue";
import StudentExamEvaluation from "./StudentExamEvaluation.vue";
// import ExamorquestiongroupDash from "../examorquestiongroup/dashboard.vue";
import ManageExamQuestions from "../manageExamQuestions.vue"
import XLSX from "xlsx";
import readXlsxFile from "read-excel-file";
export default {
  props: ['enableCo', 'enableTaxonomy', 'enableModule', 'enableOrQuestion', 'exam_id'],
  async created() {
    if (this.enableCo == false) this.viewHeader.co = false;
    if (this.enableTaxonomy == false) this.viewHeader.blooms = false;
    if (this.enableOrQuestion == false) this.viewHeader.orq = false;
    if (this.enableModule == false) this.viewHeader.module = false;
    this.fetchData = true;
    this.examId = this.exam_id;
    if (!this.examId) this.examId = this.$route.params.exam_id;
    // await this.getExam();
    // if (this.exam.co_score_evaluation) {
    //   window.alert(
    //     'This option not avilable for Activity with "Evaluation by CO score" enabled'
    //   );
    //   return;
    // }
    await Promise.all([
      this.getExam(),
      this.getExamOrQuestionGroups(),
    ]);
    if (this.exam.positive_mark) this.viewHeader.maxMark = false;
    if (this.exam.is_objective_only) {
      this.viewHeader.co = false;
      this.viewHeader.blooms = false;
      this.viewHeader.orq = false;
      this.viewHeader.module = false;
    }
    // await this.getExamOrQuestionGroups();
    await this.prepareTable();
    await this.getExamScores();
    await Promise.all([
      this.getCourseModules(),
      this.getCourseOutcomes(),
    ]);
    // await this.getCourseModules();
    // await this.getCourseOutcomes();
    await this.bloomTaxonomyOptions.forEach((bloom) => {
      this.$set(this.bloomTaxonomyOptionsObject, bloom.value, bloom.text);
    });
    this.fetchData = false;
    this.smsCount = this.$store.getters.getSmsCount.smsBalance;
  },
  components: {
    // ImportFromExcelTypeTwo,
    // ExamorquestiongroupDash,
    StudentExamEvaluation,
    ManageExamQuestions,
  },
  data() {
    return {
      smsCount: null,
      fetchData: false,
      loadExams: false,
      deleteQuestionIndex: null,
      examId: null,
      exam: {},
      questions: [],
      courseModulesOptions: [],
      courseoutcomesOptions: [],
      importFromExcel: false,
      bloomTaxonomyOptionsObject: {},
      bloomTaxonomyOptions: [
        {
          value: null,
          text: "Select Taxonomy",
          description: "",
          disabled: true,
        },
        {
          value: "6",
          text: "Creating",
          description: "User information to create something new",
        },
        {
          value: "5",
          text: "Evaluating",
          description: "Examine information and make judgement.",
        },
        {
          value: "4",
          text: "Analyzing",
          description: "Take apart from known and identify relationships.",
        },
        {
          value: "3",
          text: "Applying",
          description: "Use information in a new (but similar) situation.",
        },
        {
          value: "2",
          text: "Understanding",
          description: "Grasp meaning of instructional materials.",
        },
        {
          value: "1",
          text: "Remembering",
          description: "Recall specific facts.",
        },
      ],
      students: [],

      saveBtn: {
        text: "Save",
        disabled: false,
      },
      totalScores: {}, // processed on updateTotalMarks computed proprty
      totalMarks: null, // processed on updateTotalQuestionScore method
      zIndex: -1,
      edit: false,
      courseModulesOptionsObject: {},
      orquestiongroups: {},
      orGroupQuestionIds: {}, // to calculate student totel score 
      // enableOrQuestiongroupmodel: false,
      viewHeader: {
        maxMark: true,
        co: true,
        module: true,
        blooms: true,
        orq: true,
      },
      importExcelFile: null,
      excelImportStatus: "",
      viewImportExcelModel: false,

      expandedEvaluationStudId: null,
      activeStudentQuestionsScores: {},//pass to child
      autoEvaluationStatus: false,
      enableManageQuestionWindow: false,

      subQuestionParentIds: [], // avoid enter score for main question of subquestions
    };
  },
  computed: {
    // question without main question of subquestions
    evaluationQuestions() {
      return this.questions.filter((q) => {
        return !this.subQuestionParentIds.includes(q.id);
      });
    },
    updateTotalMarks() {
      this.students.forEach((student) => {
        this.totalScores[student.id] = 0;
        this.questions.forEach((question) => {
          if (question.subquestion_parent_id == null && question.orquestion_group_id == null) { // process only normal main question 
            if (this.subQuestionParentIds.includes(question.id)) {
              let subs = this.questions.filter((q) => {
                return `${q.subquestion_parent_id}` == `${question.id}`;
              });
              subs.forEach(subq => {
                this.totalScores[student.id] += Number(subq.scores[student.id]);
              });
            } else {
              this.totalScores[student.id] += Number(question.scores[student.id]);
            }
          }
        });

        //if orquestions exist
        for (let gkey in this.orquestiongroups) {
          let group = this.orquestiongroups[gkey];
          let bestscores = [];
          let studentgroupScores = [];
          this.orGroupQuestionIds[group.id].forEach((qId) => {
            var questionObj = this.questions.find((obj) => {
              return obj.id == qId;
            });
            if (this.subQuestionParentIds.includes(questionObj.id)) {
              let subqs = this.questions.filter((q) => {
                return `${q.subquestion_parent_id}` == `${questionObj.id}`;
              });
              let subQuestionSum = 0;
              subqs.forEach(subq => {
                subQuestionSum += Number(subq.scores[student.id]);
              });
              studentgroupScores.push(subQuestionSum);
            } else {
              studentgroupScores.push(Number(questionObj.scores[student.id]));
            }
          });

          // sort and select best no. of scores
          studentgroupScores.sort(function (a, b) {
            return b - a;
          });
          bestscores = studentgroupScores.slice(0, group.best_of_questions);
          this.totalScores[student.id] += bestscores.reduce((a, b) => a + b, 0);
        }

      });

      return this.totalScores;
    },
  },
  validations: {
    questions: {
      required,
      $each: {
        question_no: {
          required,
        },
        maxMarks: {
          required: requiredIf(function () {
            return this.exam.positive_mark == null;
          }),
          integer,
        },
      },
    },
  },

  methods: {
    async saveAndPublishResult() {
      let confirmation = `WARNING: (Balance SMS Count: ${this.smsCount}), You are going to save and send SMS message to all parents with exam score.`;
      if (confirm(confirmation) !== true) {
        return false;
      }
      await this.saveQuestionsAndScores();
      this.saveBtn = { text: "sending SMS...", disabled: true };
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/exams/${this.examId}/publishresults`;
      await this.$axios.get(url).then(() => {
        this.$toast.success("Result published successfully", {
          position: "top",
          duration: 4000,
        });
      }).catch(() => {
        this.$toast.error("Failed to sent SMS to parents", {
          position: "top",
          duration: 10000,
        });
      });

      this.saveBtn = { text: "Save", disabled: false };
    },
    validateScoreInput(question, student_id) {
      if (question.scores[student_id] == null) return false;
      if (this.exam.positive_mark) {
        if ((this.exam.negative_mark != null) && (Number(question.scores[student_id]) == Number(this.exam.negative_mark))) return false;
        if (Number(question.scores[student_id]) == Number(this.exam.positive_mark) || Number(question.scores[student_id]) == 0) return false;
      }
      // else if (question.orquestion_group_id != null &&
      //   question.orquestion_group_id in
      //   this.orquestiongroups) {
      //   return Number(question.scores[student_id]) > Number(this.orquestiongroups[question.orquestion_group_id].maximum_mark_of_question);
      // } 
      else {
        return Number(question.scores[student_id]) > Number(question.maximum_mark);
      }
      return true;
    },
    updateStudentQuestionScoreFromEvaluation(data) {
      this.questions.forEach(q => {
        if (q.id == data.question_id) {
          q.scores[this.expandedEvaluationStudId] = data.score;
        }
      });
    },
    async viewStudEvaluation(studId) {
      await this.questions.forEach(q => {
        this.activeStudentQuestionsScores[q.id] = q.scores[studId];
      });
      this.expandedEvaluationStudId = studId;
    },

    async importScoreExcel(event) {
      this.excelImportStatus = 'importing in progress ....';
      let excelData = [];
      let error = false;
      // console.log(event.target.files[0]);
      await readXlsxFile(event.target.files[0]).then((data) => {
        excelData = data;
      }).catch(() => {
        error = true;
        this.$toast.error("Error: unsupported file. Please make sure the file extension is '.xlsx' / please check the file again", {
          position: "top",
          duration: 10000,
        });
      });
      // console.log(excelData);
      // console.log(excelData.length);
      // console.log(this.students.length);
      // console.log(excelData[0].length);
      // console.log(this.questions.length);

      if (excelData.length > this.students.length || excelData[0].length > this.evaluationQuestions.length) {
        this.$toast.error(`Error: Excel row count(${excelData.length}) and column count(${excelData[0].length}) should be less than or equal to students count(${this.students.length}) and questions count(${this.questions.length})`, {
          position: "top",
          duration: 10000,
        });
        error = true;
      }

      for (let row = 0; row < this.students.length; row++) {
        if (row >= excelData.length) { break; }
        // console.log('row', row);
        for (let col = 0; col < this.evaluationQuestions.length; col++) {
          if (col >= excelData[row].length) { break; }
          // console.log('col', col);
          if (isNaN(excelData[row][col]) && excelData[row][col] != null) {
            this.$toast.error(`Score in Row: ${row + 1}  Col: ${col + 1} (${excelData[row][col]}) is not a Number. or make it empty`, {
              position: "top",
              duration: 70000,
            });
            error = true;
            row = row + this.students.length;
            break;
          }
          this.evaluationQuestions[col].scores[this.students[row].id] = excelData[row][col];
        }
      }


      this.importExcelFile = null;

      this.$forceUpdate();
      if (!error) this.$toast.success("Imported successfully", {
        position: "top",
        duration: 3000,
      });
      this.viewImportExcelModel = false;
    },
    async prepareExport() {
      this.edit = true;
      this.loadExams = true;
      await setTimeout(() => {
        this.exportToExcel("xlsx");
        this.edit = false;
        this.loadExams = false;
      }, 3000);
    },
    async exportToExcel(type, fn, dl) {
      var elt = document.getElementById("institutionUserDetails");
      var wb = await XLSX.utils.table_to_book(elt, { sheet: "Sheet JS" });
      this.loadExams = false;

      dl
        ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
        : XLSX.writeFile(wb, fn || "assessmentDetails." + (type || "xlsx"));
    },
    // checkDeleteQuestion(questionIndex) {
    //   this.deleteQuestionIndex = questionIndex;
    //   this.$bvModal.show("deleteQuestion");
    // },
    async importedFromExcel() {
      await this.getExamQuestions();
      await this.getExamScores();
      this.importFromExcel = false;
    },
    async getExam() {
      const url =
        this.$store.getters.getAPIKey.examination + `/${this.examId}`;
      await this.$axios.get(url).then((response) => {
        this.exam = response.data;
      });
    },
    async prepareTable() {
      await this.getExamParticipants();
      await this.getExamQuestions();
      // this.$forceUpdate();
    },

    async getExamParticipants() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/exams/${this.examId}/examparticipants`;
      let students = [];
      await this.$axios.get(url).then((response) => {
        students = response.data;
      });
      // students = await this.sorteStudents(students);
      this.students = students;
      return students;
    },

    async updateTotalQuestionScore() {

      // add non-or-question scores 
      // find orquestion groups and add score of or question groups and subquestions
      this.totalMarks = 0;
      let orQuestiongroupIds = [];
      await this.questions.forEach((question) => {
        if (question.subquestion_parent_id == null) {
          if (!question.orquestion_group_id) {
            if (this.subQuestionParentIds.includes(question.id)) {
              let subs = this.questions.filter((q) => {
                return `${q.subquestion_parent_id}` == `${question.id}`;
              });
              subs.forEach(subq => {
                this.totalMarks = this.totalMarks + Number(subq.maximum_mark);
              });
            } else {
              this.totalMarks = this.totalMarks + Number(question.maximum_mark);
            }
          } else {
            if (!orQuestiongroupIds.includes(question.orquestion_group_id)) {
              orQuestiongroupIds.push(question.orquestion_group_id);
              let qScore = 0;
              if (this.subQuestionParentIds.includes(question.id)) {
                let subs = this.questions.filter((q) => {
                  return `${q.subquestion_parent_id}` == `${question.id}`;
                });
                subs.forEach(subq => {
                  qScore = qScore + Number(subq.maximum_mark);
                });
              } else {
                qScore = Number(question.maximum_mark);
              }
              qScore =
                qScore *
                Number(
                  this.orquestiongroups[question.orquestion_group_id]
                    .best_of_questions
                );
              this.totalMarks = this.totalMarks + qScore;
            }
          }
        }
      });

    },

    async getExamQuestions() {
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.examination +
        `/${this.examId}/examquestions`;
      let questionsResponse = [];
      await this.$axios.get(url).then((response) => {
        questionsResponse = response.data;
      });


      // or sub questions manage
      await questionsResponse.forEach((question) => {
        if (question.subquestion_parent_id) {
          if (!this.subQuestionParentIds.includes(question.subquestion_parent_id))
            this.subQuestionParentIds.push(question.subquestion_parent_id);
        }
      });

      let questionArray = [];
      await questionsResponse.forEach((mainQuestion) => {
        if (mainQuestion.subquestion_parent_id == null) {
          // add a normal question to questionArray also its subquestions for currect order
          let tempQuestions = [mainQuestion];

          if (this.subQuestionParentIds.includes(mainQuestion.id)) {
            tempQuestions = questionsResponse.filter((q) => {
              return `${q.subquestion_parent_id}` == `${mainQuestion.id}`;
            });
            tempQuestions.push(mainQuestion);
          }
          tempQuestions.forEach(question => {
            let questionCOs = [];
            question.course_outcome.forEach((co) => {
              questionCOs.push(co.id);
            });
            let participatsQuestionsScore = {};
            this.students.forEach((student) => {
              participatsQuestionsScore[student.id] = null;
              // this.totalScores[student.id] = 0;
            });

            questionArray.push({
              id: question.id,
              question_no: question.question_no,
              name: question.name,
              maximum_mark: question.maximum_mark,
              co_mapping: questionCOs,
              module_id: question.module_id,
              taxonomy: question.blooms_taxonamy_level,
              scores: participatsQuestionsScore,
              orquestion_group_id: question.orquestion_group_id,
              subquestion_parent_id: question.subquestion_parent_id,
            });

            if (question.orquestion_group_id != null) {
              this.orGroupQuestionIds[question.orquestion_group_id].push(question.id);
            }
          });

        }
      });

      this.questions = questionArray;

      this.updateTotalQuestionScore();
    },
    async getExamScores() {
      const url =
        this.$store.getters.getAPIKey.examination +
        `/${this.examId}/examanswers`;
      let scores = [];
      await this.$axios.get(url).then((response) => {
        scores = response.data;
      });

      let questionIdKeyMappings = {}; // question index refrerences to access with question id
      await this.questions.forEach((question, questionIndex) => {
        questionIdKeyMappings[question.id] = questionIndex;
      });

      await scores.forEach((score) => {
        let questionsArrayIndex = questionIdKeyMappings[score.examquestion_id];
        this.questions[questionsArrayIndex].scores[score.student_id] =
          score.score != null
            ? score.score % 1 == 0
              ? Math.floor(score.score)
              : parseFloat(score.score).toFixed(2)
            : null;
        // this.totalScores[score.student_id] = parseInt(
        //   this.totalScores[score.student_id] + score.score
        // ).toFixed(2);
      });
      this.fetchData = false;
    },
    openManageQuestionWindow() {
      this.enableManageQuestionWindow = true;
    },
    async closeManageQuestionWindow() {
      this.fetchData = true;
      this.enableManageQuestionWindow = false;
      // clear all variables 

      this.fetchData = false;
      this.loadExams = false;
      this.deleteQuestionIndex = null;
      this.questions = [];
      this.courseoutcomesOptions = [];
      this.totalScores = {}; // processed on updateTotalMarks computed proprty
      this.totalMarks = null; // processed on updateTotalQuestionScore method
      this.zIndex = -1;
      this.edit = false;
      this.orquestiongroups = {};
      this.orGroupQuestionIds = {}; // to calculate student totel score 
      this.importExcelFile = null;
      this.excelImportStatus = "";
      this.viewImportExcelModel = false;
      this.expandedEvaluationStudId = null;
      this.activeStudentQuestionsScores = {};//pass to child
      this.autoEvaluationStatus = false;
      this.subQuestionParentIds = [];

      await this.getExamOrQuestionGroups(),
      // await this.getExamOrQuestionGroups();
      await this.getExamQuestions();
      await this.getExamScores();
      await Promise.all([
        this.getCourseOutcomes(),
      ]);
      this.$forceUpdate();
      this.saveBtn = { text: "Save", disabled: false };
      this.fetchData = false;
    },

    async getCourseModules() {
      // this.fetchData = true;
      let courseModuleUrl = this.$store.getters.getAPIKey.getCourseModules;
      const url = courseModuleUrl.replace("course_id", this.exam.course[0].id);
      await this.$axios.get(url).then((response) => {
        let modules = response.data;
        modules.forEach((c_module) => {
          this.$set(
            this.courseModulesOptionsObject,
            c_module.id,
            c_module.module
          );
          this.courseModulesOptions.push({
            value: c_module.id,
            text: c_module.module,
          });
        });
      });
    },
    async getCourseOutcomes() {
      this.courseoutcomesOptions = [];
      let courseModuleUrl = this.$store.getters.getAPIKey.getCourseOutcomes;
      const url = courseModuleUrl.replace("course_id", this.exam.course[0].id);
      let cos = await this.$axios.get(url).then((response) => {
        return response.data;
      });
      let outcomeObjects = {};
      await cos.forEach((outcome) => {
        outcomeObjects[outcome.id] = {
          value: outcome.id,
          name: outcome.code,
        };
        this.courseoutcomesOptions.push(outcomeObjects[outcome.id]);
      });
      await this.questions.forEach((q, index) => {
        let coIds = q.co_mapping;
        let selectedCos = [];
        coIds.forEach((coId) => {
          selectedCos.push(outcomeObjects[coId]);
        });
        this.questions[index].co_mapping = selectedCos;
      });
      // this.$forceUpdate();
    },

    async getExamOrQuestionGroups() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/examorquestiongroups?exam_id=${this.examId}`;
      let orquestiongroups = await this.$axios.get(url).then((response) => {
        return response.data;
      });
      this.orquestiongroups = {};
      this.orGroupQuestionIds = {}; // initiate
      await orquestiongroups.forEach((group) => {
        this.orGroupQuestionIds[group.id] = [];
        this.orquestiongroups[group.id] = group;
      });
      this.$forceUpdate();
    },
    async saveQuestionsAndScores() {
      this.saveBtn = { text: "saving...", disabled: true };
      let questions_scores = [];
      let participants = [];
      await this.questions.forEach((question) => {
        let course_outcomes = [];
        question.co_mapping.forEach((co) => {
          course_outcomes.push(co.value);
        });
        questions_scores.push({
          id: question.id,
          no: question.question_no,
          maximum_mark: question.maximum_mark,
          course_outcome: course_outcomes,
          blooms_taxonamy_level: question.taxonomy,
          module_id: question.module_id,
          scores: question.scores,
          orquestion_group_id: question.orquestion_group_id,
        });
      });

      await this.students.forEach((student) => {
        participants.push(student.id);
      });
      let postData = {
        participants: participants,
        questions_scores: questions_scores,
      };
      let evaluation = await this.postQuestionAndScore(postData);
      if (evaluation) {
        // await this.prepareTable();
        // await this.getExamScores();
        // await this.getCourseOutcomes();
        this.$forceUpdate();
        this.$toast.success("Evaluation Successfully done.");
        this.saveBtn = { text: "Save", disabled: false };
        // window.location.reload();
        // await this.getExamQuestions();
        // await this.getExamScores();
      }
      if (!evaluation) {
        return;
      }
    },

    // Post Question Score with API
    async postQuestionAndScore(postData) {
      const url =
        this.$store.getters.getAPIKey.examination +
        `/${this.examId}/questionswithscores`;
      let status = false;
      await this.$axios
        .post(url, postData)
        .then(() => {
          status = true;
        })
        .catch((error) => {
          this.$toast.error(
            "Something Went Wrong! Please try again! (" +
            error.response.data.message +
            ")"
          );
          status = false;
        });
      return status;
    },

    async genarateObjectiveScore() {
      this.autoEvaluationStatus = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/exams/${this.examId}/autoevaluation`;
      await this.$axios.get(url).then(() => {
        return true;
      });
      // await this.prepareTable();
      await this.getExamScores();
      this.$forceUpdate();
      this.autoEvaluationStatus = false;
      this.$toast.success("Evaluation Successfully done.");
    },
  },
};
</script>

<style lang="scss">
// .offlineExam table thead tr:last-child {
//   z-index: none !important;
// }
.offlineExam table {
  width: 100%;
  text-align: center;
}

// .subContentComponentCloseButton{
//   display: none !important;
// }

// input {
//   padding: 0.2rem !important;
// }

// th td {
//   padding: 0.5rem !important;
// }

// table tr:first-child th {
//   position: sticky;
//   top: 0px;
// }
</style>
