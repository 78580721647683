<template>
  <div>

    <b-button class="float-left mx-2 " pill variant="dark" v-b-modal.chooseEvaluationMethod>Choose evaluation
      options
    </b-button>

    <b-button @click="$root.clearSubContent" class="float-right mx-2 " variant="light"><i
        class="fas fa-window-close fa-1x text-danger"></i>
    </b-button>

    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div v-else class="typeOfExam">
      <b-tabs content-class="mt-3" justified v-model="tabIndex" pills lazy>
        <b-tab v-for="(tab, optionIndex) in tabs" :key="optionIndex" :title="tab.text">
          <component v-bind:is="tab.component" :enableTaxonomy="enableTaxonomy" :enableCo="enableCo"
            :enableOrQuestion="enableOrQuestion" :enableModule="enableModule" :exam_id="exam_id" />
        </b-tab>
      </b-tabs>
    </div>

    <b-modal id="chooseEvaluationMethod" v-model="modalShow" hide-footer title="Evaluation options" no-close-on-backdrop
      no-close-on-keyboard no-close-on-esc>


      <div>
        <!-- <b-form-group label="Enable:">
          <b-form-checkbox v-model="enableCo">
            Course outcome
          </b-form-checkbox>
          <b-form-checkbox v-model="enableTaxonomy">
            Blooms Taxonomy
          </b-form-checkbox>
          <b-form-checkbox v-model="enableModule">
            Course Module
          </b-form-checkbox>
          <b-form-checkbox v-model="enableOrQuestion">
            OR question feature
          </b-form-checkbox>
        </b-form-group> -->


        <b-form-group label="I want to update" v-slot="{ ariaDescribedby }">
          <b-form-radio @change="updateTabView()" v-model="selectedType" :aria-describedby="ariaDescribedby"
            name="some-radios" value="offline">
            Question score for each student with CO mapping</b-form-radio>
          <b-form-radio @change="updateTabView()" v-model="selectedType" :aria-describedby="ariaDescribedby"
            name="some-radios" value="coScore">CO
            wise score for each student</b-form-radio>
        </b-form-group>
        <!-- <b-form-group id="input-group-1" label="I want to update" label-for="input-1" description="Choose the option">
          <b-form-select v-model="selectedType" @change="updateTabView()" size="sm">
            <b-form-select-option value="">Choose option</b-form-select-option>
            <b-form-select-option value="online">Question wise score for each student</b-form-select-option>
            <b-form-select-option value="offline">Question score for each student with CO mapping</b-form-select-option>
            <b-form-select-option value="coScore">CO wise score for each student</b-form-select-option>
          </b-form-select>
        </b-form-group> -->
      </div>
      <!-- <div style="text-align: right;">
        <b-button class="mx-2" variant="success" @clcik="modalShow = false">Close
        </b-button>
      </div> -->
    </b-modal>

  </div>
</template>

<script>
// import OnlineExam from "./OnlineExam.vue";
import OfflineExam from "./OfflineExam.vue";
// import McqEvaluation from "./McqEvaluation.vue";
import ParticipantsCourseoutcomesScore from "./ParticipantsCourseoutcomesScore.vue";

export default {
  props: ['exam_id'],
  async created() {
    this.fetchData = true;
    await this.getExam();
    if (this.exam.co_score_evaluation == 1) {
      this.tabs.push({ text: "Participants vs CO's Score", component: "ParticipantsCourseoutcomesScore" });
    } else if (this.exam.co_score_evaluation == 0) {
      this.tabs.push({ text: `${this.exam.name} :Question CO mapping & score`, component: "OfflineExam" });
    } else {
      this.modalShow = true;
    }
    this.fetchData = false;
  },
  components: {
    // OnlineExam,
    OfflineExam,
    ParticipantsCourseoutcomesScore,
    // McqEvaluation,
  },
  data() {
    return {
      fetchData: false,
      tabIndex: 0,
      tabs: [
        // { text: "Answer Evaluation", component: "OnlineExam" },
        // { text: "Question CO mapping & score", component: "OfflineExam" },
        // { text: "Participants vs CO's Score", component: "ParticipantsCourseoutcomesScore" },
      ],
      selectedType: '',
      modalShow: false,
      enableCo: true,
      enableTaxonomy: true,
      enableModule: true,
      enableOrQuestion: true,
      exam: {}
    };
  },
  methods: {
    updateTabView() {
      this.tabs = [];
      // console.log(this.selectedType);
      // if (this.selectedType == 'online') {
      //   this.tabs.push({ text: "Answer Evaluation", component: "OnlineExam" });
      // } else 
      if (this.selectedType == 'offline') {
        this.tabs.push({ text: "Question CO mapping & score", component: "OfflineExam" });
      } else if (this.selectedType == 'coScore') {
        this.tabs.push({ text: "Participants vs CO's Score", component: "ParticipantsCourseoutcomesScore" });
      } else {
        // this.tabs.push({ text: "Answer Evaluation", component: "OnlineExam" });
        // this.tabs.push({ text: "Question CO mapping & score", component: "OfflineExam" });
        // this.tabs.push({ text: "Participants vs CO's Score", component: "ParticipantsCourseoutcomesScore" });
      }
      this.modalShow = false;
      // console.log('buttonclcick2222');
      // this.$bvModal.hide('chooseEvaluationMethod');
      // this.$refs['chooseEvaluationMethod'].hide()
    },
    async getExam() {
      var examId = this.$route.params.exam_id;
      if (!examId) examId = this.exam_id;
      // const url = this.$store.getters.getAPIKey.examination + `/${examId}?evaluation_option=1`;
      const url = this.$store.getters.getAPIKey.examination + `/${examId}`;

      await this.$axios
        .get(url)
        .then((response) => {
          this.exam = {};
          this.exam = response.data;
        })
        .catch((error) => {
          error;
        });
    },
  },
};
</script>

<style >
.typeofexam .nav-tabs .nav-link {
  font-size: 14px !important;
  font-weight: 600 !important;
}
</style>
