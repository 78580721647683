var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-1",staticStyle:{"background-color":"aliceblue","max-width":"95vw !important"}},[(_vm.fetchData)?_c('div',{staticClass:"loading"},[_c('load-data')],1):_vm._e(),(!_vm.fetchData)?_c('div',[_c('b-row',{staticClass:"mx-0"},[_c('b-form-group',[_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.attachmentsPreview),callback:function ($$v) {_vm.attachmentsPreview=$$v},expression:"attachmentsPreview"}},[_vm._v("Preview Attachments")])],1)],1),(_vm.answers.length == 0)?_c('p',{staticClass:"text-danger"},[_c('br'),_vm._v(" Student not answered for any questions")]):_vm._e(),_vm._l((_vm.questions),function(question,questionIndex){return _c('b-col',{key:questionIndex,staticClass:"border border-info my-2 p-1"},[_c('b-row',{staticClass:"mx-0"},[_c('b-col',{staticClass:"text-left",attrs:{"cols":"9"}},[_vm._v(_vm._s(question.question_no)+": "+_vm._s(question.name)+" "),(question.maximum_mark)?_c('span',[_vm._v(" ("+_vm._s(question.maximum_mark)+")")]):_vm._e(),_vm._l((question.files),function(questionFile,questionfIndex){return _c('div',{key:questionfIndex},[_c('br'),_vm._v(" "),_c('a',{staticClass:"text-decoration-none",staticStyle:{"font-size":"14px"},attrs:{"href":_vm.cloudRootUrl +
              questionFile.file_path +
              questionFile.file_name,"target":"_blank"}},[_vm._v(" "+_vm._s(questionFile.file_name)+" ")]),(_vm.attachmentsPreview)?_c('embed',{attrs:{"src":_vm.cloudRootUrl +
              questionFile.file_path +
              questionFile.file_name,"height":"500px","width":"500"}}):_vm._e()])})],2),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-input',{staticClass:"text-center ",attrs:{"placeholder":"Score"},on:{"change":function($event){return _vm.updateScoreInParent(question.id)}},model:{value:(_vm.questionsScore[question.id]),callback:function ($$v) {_vm.$set(_vm.questionsScore, question.id, $$v)},expression:"questionsScore[question.id]"}})],1)],1),_c('br'),(_vm.questionsAnswers[question.id])?_c('div',[(_vm.questionsAnswers[question.id].answer)?_c('p',[_vm._v("Answer: "+_vm._s(_vm.questionsAnswers[question.id].answer))]):_vm._e(),(_vm.questionsAnswers[question.id].choice_id)?_c('p',[_vm._v("Choice: "),(_vm.questionsChoices[question.id][_vm.questionsAnswers[question.id].choice_id].is_right_choice)?_c('span',{staticClass:"text-success"},[_vm._v(" "+_vm._s(_vm.questionsChoices[question.id][_vm.questionsAnswers[question.id].choice_id].name)+" ")]):_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.questionsChoices[question.id][_vm.questionsAnswers[question.id].choice_id].name)+" ")])]):_vm._e(),_c('div',_vm._l((_vm.questionsAnswers[question.id].files),function(file,fileIndex){return _c('b-col',{key:fileIndex},[_vm._v(" File: "),_c('a',{staticClass:"text-decoration-none",staticStyle:{"font-size":"14px"},attrs:{"href":_vm.cloudRootUrl +
              file.file_path +
              file.file_name,"target":"_blank"}},[_vm._v(" "+_vm._s(file.file_name)+" ")]),(_vm.attachmentsPreview)?_c('embed',{attrs:{"src":_vm.cloudRootUrl +
            file.file_path +
            file.file_name,"height":"700px","width":"500"}}):_vm._e()])}),1)]):_c('span',{staticClass:"text-info"},[_vm._v("Not Answered")])],1)})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }