<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="evaluate student-co-evaluation" v-else>
      <b-container fluid>
        <br />
        <b-row>
          <b-col cols="6">
            <h3>Students Vs Course outcomes Score Table</h3>
          </b-col>
          <!-- <b-col cols="6" class="text-right">
            <span v-if="exam_id == null" @click="closeCurrentTab()" class="btn m-0">
              <i class="fas fa-window-close fa-4x text-danger"></i>
            </span>
            <span v-else @click="$root.clearSubContent" class="btn m-0">
              <i class="fas fa-window-close fa-4x text-danger"></i>
            </span>
          </b-col> -->
        </b-row>
        <b-row>
          <b-col md="12">
            <table class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th rowspan="3">Roll No</th>
                  <th rowspan="3">Participant</th>
                  <th :colspan="courseoutcomes.length">Course outcomes</th>
                  <th rowspan="3">Total</th>
                  <th rowspan="3">%</th>
                </tr>
                <tr>
                  <th v-for="(co, coIndex) in courseoutcomes" :key="coIndex">
                    {{ co.code }}
                  </th>
                </tr>
                <tr>
                  <th v-for="(co, coIndex) in courseoutcomes" :key="coIndex">
                    <input type="number" min="0" v-model="coMaxScores[co.id]" placeholder="max. score" />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(student, studentIndex) in students" :key="studentIndex">
                  <td>{{ student.roll_number }}</td>
                  <td>
                    {{ student.first_name || " " }}
                    {{ student.last_name || " " }}
                  </td>
                  <td v-for="(co, coIndex) in courseoutcomes" :key="coIndex">
                    <input type="number" min="0" v-model="scoreDataobject[student.id][co.id]"
                      :class="{ 'invalid-input': Number(scoreDataobject[student.id][co.id]) > Number(coMaxScores[co.id]) }"
                      placeholder="score" />
                  </td>
                  <td>{{ studentsTotelScore[student.id] }}/{{ coMaxScoreSum }}</td>
                  <td><span v-if="(coMaxScoreSum > 0)"> {{ Math.round((studentsTotelScore[student.id] * 10000) /
                      coMaxScoreSum) / 100
                  }}</span></td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-button type="submit" variant="primary" :disabled="savebutton.disabled" @click="saveScores()">{{
                savebutton.text
            }}</b-button>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ['exam_id'],
  async created() {
    this.fetchData = true;
    // console.log();
    await this.getExam();
    // if (!this.exam.co_score_evaluation) {
    //   window.alert(
    //     'This option not avilable for Activity with "Evaluation by CO score" disabled'
    //   );
    //   return;
    // }
    await this.getCourseoutcomes();
    await this.getExamParticipants();
    await this.prepareParticipantScoreData(); // initiate and set values 
    if (this.exam.max_co_scores != null) {
      for (const coId in this.exam.max_co_scores) {
        if (coId in this.coMaxScores) {
          this.coMaxScores[coId] = this.exam.max_co_scores[coId];
        }
      }
    }
    this.fetchData = false;
    // this.getCourseoutcomes();
  },
  data() {
    return {
      fetchData: false,
      scoreDataobject: {}, // {student id: co id: score,}
      studentsTotelScoreObject: {},
      coMaxScores: {},
      exam: {},
      students: [],
      courseoutcomes: [],
      savebutton: {
        text: "Save",
        disabled: false,
      },
    };
  },
  computed: {
    studentsTotelScore() {
      let studentsTotelScoreObject = {};
      for (const studId in this.scoreDataobject) {
        studentsTotelScoreObject[studId] = 0;
        for (const coId in this.scoreDataobject[studId]) {
          studentsTotelScoreObject[studId] += Number(this.scoreDataobject[studId][coId]);
        }
      }
      return studentsTotelScoreObject;
    },
    coMaxScoreSum() {
      let scoreSum = 0;
      for (const key in this.coMaxScores) {
        scoreSum += Number(this.coMaxScores[key]);
      }
      return scoreSum;
    },
  },
  methods: {
    // async assignMarks() {
    //   this.marksBtn.text = "Please Wait...";
    //   this.marksBtn.disabled = true;
    //   const studentScore = {
    //     exam_scores: [],
    //   };
    //   await this.studentSheet.forEach((student) => {
    //     if (student.studentId != "") {
    //       studentScore.exam_scores.push({
    //         examquestion_id: student.questionId,
    //         student_id: student.studentId,
    //         score: student.marks,
    //       });
    //     }
    //   });
    //   const url = this.$store.getters.getAPIKey.examAnswers + `/marks`;

    //   axios
    //     .post(url, studentScore)
    //     .then((response) => {
    //       this.clearResponse(this.studentSheet[0].studentId);
    //       response;
    //     })
    //     .catch((error) => {
    //       //   this.clearResponse();
    //       error;
    //     });
    // },
    async getExam() {
      var examId = this.$route.params.exam_id;
      if (!examId) examId = this.exam_id;
      const url = this.$store.getters.getAPIKey.examination + `/${examId}`;

      await axios
        .get(url)
        .then((response) => {
          this.exam = {};
          this.exam = response.data;
          // this.getCourse();
          // response;
        })
        .catch((error) => {
          if (error.response) {
            this.$toast.error(`${error.response.data.message}`, {
              position: "top-right",
              duration: 4000,
            });
          }
        });
    },
    async getExamParticipants() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/exams/${this.exam.id}/examparticipants`;

      await this.$axios
        .get(url)
        .then((response) => {
          this.students = response.data;
          // this.prepareParticipantScoreData();
        })
        .catch((error) => {
          if (error.response) {
            this.$toast.error(`${error.response.data.message}`, {
              position: "top-right",
              duration: 4000,
            });
          }
        });
    },
    async prepareParticipantScoreData() {
      await this.sortedArray();

      await this.students.forEach((student) => {
        this.$set(this.scoreDataobject, student.id, {})
        // this.scoreDataobject[student.id] = {};
        this.courseoutcomes.forEach((co) => {
          this.$set(this.scoreDataobject[student.id], co.id, null)
          // this.scoreDataobject[student.id][co.id] = null;
        });
      });

      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/exams/${this.exam.id}/examparticipants/courseoutcomes/score`;
      let participantsdata = [];
      await axios
        .get(url)
        .then((response) => {
          participantsdata = response.data;
        })
        .catch((error) => {
          if (error.response) {
            this.$toast.error(`${error.response.data.message}`, {
              position: "top-right",
              duration: 4000,
            });
          }
        });
      await participantsdata.forEach((student) => {
        if (student.institution_user_id in this.scoreDataobject) {
          let studentcoscore = student.courseoutcomes_score;
          if (studentcoscore) {
            for (var cokey in studentcoscore) {
              if (cokey in this.scoreDataobject[student.institution_user_id])
                this.scoreDataobject[student.institution_user_id][cokey] =
                  studentcoscore[cokey];
            }
          }
        }
      });
    },
    async getCourseoutcomes() {
      this.fetchData = true;
      let courseOutcomeUrl = this.$store.getters.getAPIKey.getCourseOutcomes;
      // console.log(this.exam);
      const url = courseOutcomeUrl.replace("course_id", this.exam.course[0].id);
      await axios
        .get(url)
        .then((response) => {
          this.courseoutcomes = response.data;
          this.fetchData = false;
          // console.log(response);
        })
        .catch((error) => {
          if (error.response) {
            this.$toast.error(`${error.response.data.message}`, {
              position: "top-right",
              duration: 4000,
            });
          }
        });

      await this.courseoutcomes.forEach((co) => {
        // this.coMaxScores[co.id] = null;
        this.$set(this.coMaxScores, co.id, null)
      });
    },

    async saveScores() {
      this.savebutton = {
        text: "Saving...",
        disabled: true,
      };
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/examparticipants/courseoutcomes/score`;
      var postData = {};
      postData["exam"] = this.exam.id;
      postData["participants_cos_score"] = this.scoreDataobject;
      postData["max_co_scores"] = this.coMaxScores;
      await this.$axios
        .post(url, postData)
        .then(() => {
          this.$toast.success(`Scores saved successfully`, {
            position: "top-right",
            duration: 3000,
          });
          this.$emit("GroupCreated", true);
        })
        .catch((error) => {
          this.savebutton = {
            text: "Save",
            disabled: false,
          };
          if (error.response) {
            this.$toast.error(
              `Failed to save : ${error.response.data.message}`,
              {
                position: "top-right",
                duration: 4000,
              }
            );
          }
        });
      this.savebutton = {
        text: "Save",
        disabled: false,
      };

      // console.log(postData);
    },

    async sortedArray() {
      this.fetchData = true;
      // await this.students.sort(function(a, b) {
      //   return (
      //     a.roll_number - b.roll_number ||
      //     a.first_name.localeCompare(b.first_name)
      //   );
      // });
      // this.fetchData = false;
      return true;
    },
  },
};
</script>

<style >
.student-co-evaluation table td {
  position: relative;
}

.invalid-input {
  border: 1px solid red !important;
}

.student-co-evaluation table td input {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  border: none;
  padding: 10px;
  box-sizing: border-box;
}

</style>
