<template>
  <div class="p-1" style="background-color: aliceblue; max-width:95vw !important;">
    <!-- <b-container class="bv-example-row">
      <b-row>
        <b-col>1 of 2</b-col>
        <b-col>2 of 2</b-col>
      </b-row>
    </b-container> -->
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>

    <div v-if="!fetchData">

      <!-- <div class="closeBtn mb-2 text-right">
        <p @click="closeCurrentTab()" class="btn m-0">
          <i class="fas fa-window-close fa-4x text-danger"></i>
        </p>
      </div> -->
      <b-row class="mx-0">
        <b-form-group>
          <b-form-checkbox switch v-model="attachmentsPreview">Preview Attachments</b-form-checkbox>
        </b-form-group>
      </b-row>
      <p v-if="answers.length == 0" class="text-danger"><br> Student not answered for any questions</p>
      <!-- {{ questionsScore }} -->
      <b-col class="border border-info my-2 p-1" v-for="(question, questionIndex) in questions" :key="questionIndex">
        <b-row class="mx-0">
          <b-col cols="9" class="text-left">{{ question.question_no }}: {{ question.name }} <span
              v-if="question.maximum_mark"> ({{ question.maximum_mark
              }})</span>
            <div v-for="(questionFile, questionfIndex) in question.files" :key="questionfIndex">
              <br> <a :href="
                cloudRootUrl +
                questionFile.file_path +
                questionFile.file_name
              " class="text-decoration-none" target="_blank" style="font-size: 14px">
                {{ questionFile.file_name }}
              </a>

              <embed v-if="attachmentsPreview" :src="
                cloudRootUrl +
                questionFile.file_path +
                questionFile.file_name
              " height="500px" width="500" />
            </div>
          </b-col>
          <b-col cols="3">
            <b-form-input v-model="questionsScore[question.id]" @change="updateScoreInParent(question.id)"
              placeholder="Score" class="text-center "></b-form-input>
          </b-col>
        </b-row>
        <br>
        <!-- <b-row  class="mx-0">
          <b-col md="9"></b-col>
          <b-col md="3"></b-col>
        </b-row> -->
        <div v-if="questionsAnswers[question.id]">
          <p v-if="questionsAnswers[question.id].answer">Answer: {{ questionsAnswers[question.id].answer }}</p>
          <p v-if="questionsAnswers[question.id].choice_id">Choice:
            <span class="text-success"
              v-if="questionsChoices[question.id][questionsAnswers[question.id].choice_id].is_right_choice">
              {{ questionsChoices[question.id][questionsAnswers[question.id].choice_id].name }}
            </span>
            <span class="text-danger" v-else>
              {{ questionsChoices[question.id][questionsAnswers[question.id].choice_id].name }}
            </span>
          </p>
          <div>
            <b-col v-for="(file, fileIndex) in questionsAnswers[question.id].files" :key="fileIndex">
              File:
              <a :href="
                cloudRootUrl +
                file.file_path +
                file.file_name
              " class="text-decoration-none" target="_blank" style="font-size: 14px">
                {{ file.file_name }}
              </a>
              <embed v-if="attachmentsPreview" :src="
              cloudRootUrl +
              file.file_path +
              file.file_name" height="700px" width="500" />
              <!-- <a style="font-size: 12px; font-weight: 600" :href="
                      userAttachments +
                      file.file_path +
                      file.file_name
                    " class="text-decoration-none" target="_blank">
                      {{ file.file_name }}
                    </a> -->
            </b-col>
          </div>
        </div>
        <span v-else class="text-info">Not Answered</span>
      </b-col>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["student_id", "exam_id", "exam", "activeStudentQuestionsScores"],
  async created() {
    for (const key in this.activeStudentQuestionsScores) {
      this.$set(this.questionsScore, key, this.activeStudentQuestionsScores[key]);
    }
    this.questionsScore = this.activeStudentQuestionsScores;
    this.cloudRootUrl = this.$store.getters.getAPIKey.userAttachments;
    this.fetchData = true;
    await Promise.all(
      [this.getStudentAnswers(),
      this.getExamQuestions(),]
    );
    this.questions.forEach(q => {
      this.questionsAnswers[q.id] = null;
      this.questionsChoices[q.id] = {};
      q.choices.forEach(choice => {
        this.questionsChoices[q.id][choice.id] = choice;
      });
    });
    this.answers.forEach(ans => {
      this.questionsAnswers[ans.examquestion_id] = ans;
    });
    this.fetchData = false;
  },
  data() {
    return {
      fetchData: false,
      questions: [],
      answers: [],
      questionsAnswers: {},
      attachmentsPreview: true,
      questionsScore: {},
      questionsChoices: {},
      cloudRootUrl: null,
    }
  },
  methods: {
    windowInnerWidth() {
      return window.innerWidth;
    },
    updateScoreInParent(question_id) {
      let data = { question_id: question_id, score: this.questionsScore[question_id] };
      this.$emit('updateStudScore', data);
    },
    async getStudentAnswers() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/exams/${this.exam_id}/institutionusers/${this.student_id}/examanswers`;
      await axios
        .get(url)
        .then((response) => {
          //   this.studentSheet = [];
          this.answers = response.data;
          // console.log(answers);
          // if (answers.length != 0) {
          //   answers.forEach((answer) => {
          //     let quesId = answer.examquestion_id;
          //     this.studentSheet.forEach((checkAns) => {
          //       if (quesId == checkAns.questionId) {
          //         checkAns.answer = answer.answer;
          //         checkAns.studentId = answer.student_id;
          //         checkAns.studentFiles = answer.files;
          //         if (answer.score != null) {
          //           let marks = answer.score;
          //           checkAns.marks =
          //             marks != null
          //               ? marks % 1 == 0
          //                 ? Math.floor(marks)
          //                 : parseFloat(marks).toFixed(1)
          //               : null;
          //           // checkAns.marks = parseFloat(answer.score).toFixed(1);
          //         }
          //       }
          //     });
          //   });
          // }
        })
        .catch((error) => {
          error;
        });
    },
    async getExamQuestions() {
      this.StudentExamSheet = true;

      const url =
        this.$store.getters.getAPIKey.examination +
        `/${this.exam_id}/examquestions`;
      await axios
        .get(url)
        .then((response) => {
          this.questions = response.data;
        })
        .catch((error) => {
          error;
        });
    },
    // async getorquestiongroups() {
    //   this.resourceaction = "list";
    //   this.fetchData = true;
    //   const url =
    //     this.$store.getters.getAPIKey.mainAPI +
    //     `/examorquestiongroups?exam_id=${this.exam_id}`;
    //   await axios
    //     .get(url)
    //     .then((response) => {
    //       this.orquestiongroups = response.data;
    //       this.fetchData = false;
    //     })
    //     .catch(() => {
    //       this.$toast.error("Failed to get List", {
    //         position: "top",
    //         duration: 3000,
    //       });
    //       this.fetchData = false;
    //     });
    // },
  },
};
</script>

<style >
</style>
