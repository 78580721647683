<template>
  <div class="container">

    <b-form-group>
      <label class="required-label" for=""></label>
      <div class="d-flex">
        <b-form-radio :value="'format_only'" class="ml-1" v-model="copy_by">Copy Format</b-form-radio>
        <b-form-radio class="ml-5" :value="'question_with_format'" v-model="copy_by">Copy Format With
          Question</b-form-radio>
      </div>
    </b-form-group>

    <b-form-group v-if="copy_by">
      <label class="required-label" for="">Choose Academic Year to be copied from:</label>
      <b-form-select :options="$acedemicYears" v-model="academic_year">
      </b-form-select>
    </b-form-group>

    <b-form-group v-if="academic_year">
      <label class="required-label" for="">Choose Programme:</label>
      <b-form-select @change="getSubgroups()" :options="usergroups" v-model="usergroup_id">
      </b-form-select>
    </b-form-group>

    <b-form-group v-if="(academic_year && usergroup_id)">
      <label class="required-label" for="">{{ 'Choose ' + subgroupCustomName + ':'
      }}</label>
      <b-form-select @change="getCourses()" :options="usersubgroups" v-model="usersubgroup_id"
        :disabled="false"></b-form-select>
    </b-form-group>

    <b-form-group v-if="(academic_year && usergroup_id && usersubgroup_id)">
      <label class="required-label" for="">Choose Course:</label>
      <b-form-select @change="getActivities()" :options="courses" v-model="course_id">
      </b-form-select>
    </b-form-group>

    <b-form-group v-if="(academic_year && usergroup_id && usersubgroup_id && course_id)">
      <label class="required-label" for="">Choose Activity to be copied:</label>
      <b-form-select :options="exams" v-model="exam_id">
      </b-form-select>
    </b-form-group>

    <div class="mt-3 text-right">
      <!-- <b-button variant="success" class="mr-2" size="sm" @click="copyQuestion(true)">Copy question</b-button> -->
      <b-button variant="success" size="sm" @click="copyQuestion()">Proceed to copy</b-button>
    </div>

  </div>
</template>

<script>

export default {
  props: {
    examId: {},
    courseId: {},
    finishEditQuestion: Function,
  },
  async created() {
    this.subgroupCustomName = this.$store.getters.getCustomNamings.userSubgroup.custom_name ?? "Usersubgroup";
    this.getUserGroups();
    // await Promise.all([
    // ]);
  },
  computed: {
  },
  components: {
  },
  data() {
    return {
      fetchData: false,
      subgroupCustomName: '',

      courses: [],
      usersubgroups: [],
      usergroups: [],
      exams: [],

      copy_by: null,//question_with_format, format_only
      academic_year: null,
      usersubgroup_id: null,
      usergroup_id: null,
      course_id: null,
      exam_id: null,
    };
  },
  methods: {
    async copyQuestion() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/examquestions/copy`;
      await this.$axios
        .post(url, { copy_from_exam_id: this.exam_id, copy_to_exam_id: this.examId, format_with_question: this.copy_by == 'question_with_format' })
        .then(() => {
          this.finishEditQuestion();
        })
        .catch((error) => {
          error;
        });
    },

    async getCourses() {
      this.courses = [];

      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usersubgroups/${this.usersubgroup_id}/courses`;
      await this.$axios
        .get(url)
        .then((response) => {
          response.data.forEach((course) => {
            // if(this.courseId != course.id)
            this.courses.push({
              value: course.id,
              text: `${course.name} (${course.code})`,
            });
          });
        })
        .catch((error) => {
          error;
        });
    },

    async getSubgroups() {
      this.usersubgroups = [];
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usergroups/${this.usergroup_id}/usersubgroups?academic_year=${this.academic_year}`;
      await this.$axios
        .get(url)
        .then((response) => {
          response.data.forEach((subgroup) => {
            // this.usersubgroupObjects[subgroup.id] = subgroup;
            this.usersubgroups.push({
              value: subgroup.id,
              text: `${subgroup.name} (${subgroup.code})`,
            });
          });
        })
        .catch((error) => {
          error;
        });
    },

    async getUserGroups() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usergroups?allusergroups=1`;
      await this.$axios
        .get(url)
        .then((response) => {
          response.data.forEach((group) => {
            this.usergroups.push({
              value: group.id,
              text: `${group.name} (${group.code}` + (group.scheme ? `-${group.scheme} Scheme)` : ')'),
            });
          });
        })
        .catch((error) => {
          error;
        });
    },

    async getActivities() {
      this.exams = [];

      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/course/${this.course_id}/exams?copy_question=1`;
      await this.$axios
        .get(url)
        .then((response) => {
          response.data.forEach((exam) => {
            this.exams.push({
              value: exam.id,
              text: `${exam.name}`,
            });
          });
        })
        .catch((error) => {
          error;
        });
    },
  }
};
</script>

<style >

</style>
